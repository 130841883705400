import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
	typography: {
		fontFamily: `'Poppins', sans-serif`,
		fontSize: 14,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
	},
	palette: {
		primary: {
			main: "#00202a",
			secondary: {
				main: "#086788",
			},
			background: {
				main: "#f1fffa",
			},
		},
	},
});

export default theme;
