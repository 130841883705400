import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import InputAdornment from "@mui/material/InputAdornment";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import LockIcon from "@mui/icons-material/Lock";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const useStyles = makeStyles((theme) => ({
	form: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: "20px",
		borderRadius: "5px",
	},
	textField: {
		margin: "10px",
	},
	error: {
		color: "red",
		fontSize: "12px",
		marginBottom: "5px",
		marginTop: "-10px",
	},
	submitButton: {
		margin: "10px",
	},
}));

const Registration = () => {
	const classes = useStyles();

	const [username, setUsername] = useState("");
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [errors, setErrors] = useState({});

	const handleSubmit = (event) => {
		event.preventDefault();
		const errors = {};
		const alphanumericRegex = /^[a-zA-Z0-9]+$/;
		const phoneRegex = /^(\+91[-\s]?)?[0]?(91)?[789]\d{9}$/;

		const passwordRegex =
			/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;

		if (!username) {
			errors.username = "Username is required";
		} else if (username.length < 8) {
			errors.username = "Username must be at least 8 characters";
		} else if (!alphanumericRegex.test(username)) {
			errors.username =
				"Username should contain only alphanumeric characters";
		}

		if (!name) {
			errors.name = "Name is required";
		}

		if (!email) {
			errors.email = "Email is required";
		} else if (!/\S+@\S+\.\S+/.test(email)) {
			errors.email = "Email is invalid";
		}

		if (!phone) {
			errors.phone = "Phone number is required";
		} else if (!phoneRegex.test(phone)) {
			errors.phone = "Phone number is invalid";
		}

		if (!password) {
			errors.password = "Password is required";
		} else if (password.length < 8) {
			errors.password = "Password must be at least 8 characters";
		} else if (!passwordRegex.test(password)) {
			errors.password =
				"Password must have at least 1 special character and 1 number";
		}

		if (!confirmPassword) {
			errors.confirmPassword = "Please confirm your password";
		} else if (password !== confirmPassword) {
			errors.confirmPassword = "Passwords do not match";
		}

		if (Object.keys(errors).length === 0) {
			// Submit the form
			console.log("Submitting form");
		} else {
			setErrors(errors);
		}
	};

	return (
		<React.Fragment>
			<form
				noValidate
				className={classes.form}
				onSubmit={handleSubmit}
			>
				<TextField
					label="Username"
					id="username"
					type="text"
					variant="outlined"
					value={username}
					onChange={(event) => setUsername(event.target.value)}
					error={errors.username}
					helperText={errors.username}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<AccountCircleIcon />
							</InputAdornment>
						),
					}}
					style={{ marginBottom: "25px", width: "400px" }}
				/>

				<TextField
					label="Name"
					id="name"
					type="text"
					variant="outlined"
					value={name}
					onChange={(event) => setName(event.target.value)}
					error={errors.name}
					helperText={errors.name}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<PersonIcon />
							</InputAdornment>
						),
					}}
					style={{ marginBottom: "25px", width: "400px" }}
				/>

				<TextField
					label="Email"
					id="email"
					type="email"
					variant="outlined"
					value={email}
					onChange={(event) => setEmail(event.target.value)}
					error={errors.email}
					helperText={errors.email}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<EmailIcon />
							</InputAdornment>
						),
					}}
					style={{ marginBottom: "25px", width: "400px" }}
				/>

				<TextField
					label="Phone"
					id="phone"
					type="tel"
					variant="outlined"
					value={phone}
					onChange={(event) => setPhone(event.target.value)}
					error={errors.phone}
					helperText={errors.phone}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<PhoneAndroidIcon />
							</InputAdornment>
						),
					}}
					style={{ marginBottom: "25px", width: "400px" }}
				/>

				<TextField
					label="Password"
					id="password"
					type="password"
					variant="outlined"
					value={password}
					onChange={(event) => setPassword(event.target.value)}
					error={errors.password}
					helperText={errors.password}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<LockIcon />
							</InputAdornment>
						),
					}}
					style={{ marginBottom: "25px", width: "400px" }}
				/>

				<TextField
					label="Confirm Password"
					id="confirmPassword"
					type="password"
					variant="outlined"
					value={confirmPassword}
					onChange={(event) => setConfirmPassword(event.target.value)}
					error={errors.confirmPassword}
					helperText={errors.confirmPassword}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<EnhancedEncryptionIcon />
							</InputAdornment>
						),
					}}
					style={{ marginBottom: "25px", width: "400px" }}
				/>

				<Button
					type="submit"
					variant="contained"
					color="secondary"
					className={classes.submitButton}
					endIcon={<ArrowRightAltIcon />}
				>
					Get Started
				</Button>
			</form>
		</React.Fragment>
	);
};

export default Registration;
