import React from "react";

const About = () => (
	<React.Fragment>
		<h1>About Tasty Menu!</h1>
		<p>We are ...</p>
	</React.Fragment>
);

export default About;
