import React from "react";
import { BrowserRouter } from "react-router-dom";
import FoodiesRoutes from "./routes";
import FoodiesHeader from "./components/Header/header";

const App = () => (
	<BrowserRouter>
		<div>
			<FoodiesHeader />
			<FoodiesRoutes />
		</div>
	</BrowserRouter>
);

export default App;
