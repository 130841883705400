import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	title: {
		flexGrow: 1,
		textAlign: "center",
	},
	logo: {
		height: "60px",
		marginRight: theme.spacing(1),
	},
	link: {
		textDecoration: "none",
		color: "inherit",
		margin: theme.spacing(0, 2),
		"&:hover": {
			color: theme.palette.secondary.main,
		},
	},
}));

function Header() {
	const classes = useStyles();

	return (
		<React.Fragment className={classes.root}>
			<AppBar position="static">
				<Toolbar>
					<Grid
						container
						alignItems="center"
					>
						<Grid
							item
							xs={3}
						>
							<Link to="/">
								<img
									src={`${process.env.PUBLIC_URL}/assets/images/brand_logo.png`}
									className={classes.logo}
									alt="Logo"
								/>
							</Link>
						</Grid>
						<Grid
							item
							xs={6}
						>
							<Typography
								variant="h4"
								className={classes.title}
							>
								<Link
									to="/"
									className={classes.link}
								>
									Tasty Menu
								</Link>
							</Typography>
						</Grid>
						<Grid
							item
							xs={3}
						>
							<Grid
								container
								justifyContent="flex-end"
							>
								<Button
									color="inherit"
									component={Link}
									to="/about"
								>
									About
								</Button>
								<Button
									color="inherit"
									component={Link}
									to="/registration"
								>
									Registration
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
		</React.Fragment>
	);
}

export default Header;
