import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './components/Home/home';
import About from './components/About/about';
import Registration from './components/Registration/registration';

const FoodiesRoutes = () => (
  <Routes>
    <Route path="/" caseSensitive={true} element={<Home />} />
    <Route path="/about" caseSensitive={true} element={<About />} />
    <Route path="/registration" caseSensitive={true} element={<Registration />} />
    <Route path="/login" caseSensitive={true} element={<About />} />
  </Routes>
);

export default FoodiesRoutes;
