import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(4),
		background: `url('/background-image.jpg') no-repeat center center fixed`,
		backgroundSize: "cover",
	},
	title: {
		fontWeight: "bold",
		fontSize: "4rem",
		marginBottom: theme.spacing(4),
		[theme.breakpoints.down("sm")]: {
			fontSize: "2rem",
		},
	},
	subtitle: {
		fontSize: "2rem",
		marginBottom: theme.spacing(8),
		[theme.breakpoints.down("sm")]: {
			fontSize: "1.5rem",
		},
	},
	ctaButton: {
		textAlign: "center",
		fontWeight: "bold",
		fontSize: "1.5rem",
		color: theme.palette.common.white,
		background: theme.palette.secondary.main,
		padding: theme.spacing(2, 6),
		"&:hover": {
			background: theme.palette.secondary.dark,
		},
		[theme.breakpoints.down("sm")]: {
			fontSize: "1rem",
			padding: theme.spacing(1, 4),
		},
	},
}));

const Home = () => {
	const classes = useStyles();

	return (
		<React.Fragment className={classes.root}>
			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
			>
				<Grid
					item
					xs={12}
				>
					<Typography
						variant="h1"
						className={classes.title}
					>
						Welcome to TastyMenu.com
					</Typography>
					<Typography
						variant="h3"
						className={classes.subtitle}
					>
						Your one-stop-shop for delicious, high-quality meals
						delivered straight to your door.
					</Typography>
					<Button
						variant="contained"
						className={classes.ctaButton}
						to="/registration"
					>
						Order Now
					</Button>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};

export default Home;
