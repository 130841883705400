import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import "./index.css"; // import your global CSS file
import App from "./App";

const rootElement = document.getElementById("root");

ReactDOM.createRoot(rootElement).render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<App />
		</ThemeProvider>
		,
	</React.StrictMode>,
);
